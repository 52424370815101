<template>
    <settings-wrapper>
        <template v-slot:child>
            <div class="box-border w-60-l w-90 center mt4">
                <div>
                    <div class="box-border-bottom f4 b pa3">Account Settings</div>
                    <!-- <div class="pa3">
                        <div class="flex items-center justify-between pb3 box-border-bottom f5 b">
                        <div>Password</div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Phone Number</div>
                            <input type="number" class="pa2 box-border" name="" placeholder="+12345678901" id="phoneNumber" />
                        </div>
                        </div>
                    </div> -->
                    <!-- <div class="pa3">
                        <div class="flex items-center justify-between pb3 box-border-bottom f5 b">
                            <div class="flex flex-column">
                                <div class="b pt3 pb2">Email Address</div>
                                <input type="text" class="pa2 box-border" name="" placeholder="Donye@yahoo.com" id="orgEmail" />
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom">
                            <div>
                                <div class="f5 b pb2">Change Password</div>
                                <div class="lh-copy pr1">
                                    It is advised you change your password every once month
                                </div>
                            </div>
                            <router-link :to="{ name: 'ChangePassword' }" class="pv4-lx pv3x mini-spacing">
                                <button class="btn2 box-border">Change</button>
                            </router-link>
                        </div>
                    </div> -->

                    <div class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom">
                            <div>
                                <div class="f5 b pb2">Asset</div>
                                <div class="lh-copy pr1">
                                    Default Asset Lifespan
                                </div>
                            </div>
                            <router-link :to="{ name: 'Lifespan' }" class="pv4-lx pv3x mini-spacing">
                                <button class="btn2 box-border">Show</button>
                            </router-link>
                        </div>
                    </div>
                    <div class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom ">
                            <div>
                                <div class="f5 b pb2">Payment Types</div>
                                <div class="lh-copy pr1">
                                    Manage all available business bank accounts on Simplebks
                                </div>
                            </div>
                            <router-link :to="{ name: 'BankAccounts' }" class="pv4-lx pv3x mini-spacing">
                                <button class="btn2 box-border">Manage</button>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="plan?.businessPlan !== 'Micro Business Plan'" class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom ">
                            <div>
                                <div class="f5 b pb2">General Stock Level</div>
                                <div class="lh-copy pr1">
                                    Set your minimum stock level for all products on Simplebks
                                </div>
                            </div>
                            <div class="pv4-lx pv3x mini-spacing entity-form">
                                <input type="number" min="1" v-model="state.stockLevel" @change="updateAccountStockLevel" />
                            </div>
                        </div>
                    </div>
                    <div class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom ">
                            <div>
                                <div class="f5 b pb2">Tax/VAT default rate (%)</div>
                                <div class="lh-copy pr1">
                                    Set your store tax/vat default rate (in percentage)
                                </div>
                            </div>
                            <div class="pv4-lx pv3x mini-spacing entity-form">
                                <input type="number" min="0" v-model="state.tax" @change="updateAccountTaxDefault" />
                            </div>
                        </div>
                    </div>
                    <div class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom ">
                            <div>
                                <div class="f5 b pb2">Currency</div>
                                <div class="lh-copy pr1">
                                    Choose your preffered currency
                                </div>
                            </div>
                            <div class="pv4-lx pv3x mini-spacing entity-form">
                                <select
                                    @change="onChangeCurrencySelect(currency)"
                                    v-model="currency"
                                    name=""
                                    id=""
                                    style="width: 12rem;"
                                >
                                    <option value="">Select currency</option>
                                    <option value="NGN">Naira</option>
                                    <option value="USD">Dollar</option>
                                    <option value="EUR">Euro</option>
                                    <option value="GBP">Pounds</option>
                                    <option value="GHS">Ghana Cedis</option>
                                    <option value="ZAR">SA Rand</option>
                                    <option value="CFA">CFA Franc</option>
                                    <option value="KES">Kenya KES</option>
                                    <option value="EGP">Egypt EGP</option>
                                    <option value="NAD">Namibia NAD</option>
                                    <option value="JPY">Yen</option>
                                    <option value="CNY">Renminbi</option>
                                    <option value="KRW">Won</option>
                                    <option value="INR">Rupee</option>
                                    <option value="IDR">Rupiah</option>
                                    <option value="MYR">Ringgit</option>
                                    <option value="PHP">Peso</option>
                                    <option value="TWD">Taiwan Dollar</option>
                                    <option value="THB">Baht</option>
                                    <option value="VND">Dong</option>
                                    <option value="AUD">Australian Dollar</option>
                                    <option value="BRL">Real</option>
                                    <option value="CAD">Canadian Dollar</option>
                                    <option value="CHF">Swiss Franc</option>
                                    <option value="CZK">Czech Koruna</option>
                                    <option value="DKK">Danish Krone</option>
                                    <option value="HKD">Hong Kong Dollar</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="plan?.businessPlan !== 'Micro Business Plan'" class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom ">
                            <div>
                                <div class="f5 b pb2">Work in Progress (WIP)</div>
                                <div class="lh-copy pr1">
                                    Manage your raw material flow on Simplebks
                                </div>
                            </div>
                            <toggle-switch :checked="state.wipStatus" @setCheckValue="setCheckValue" />
                        </div>
                    </div>
                    <div v-if="plan?.businessPlan !== 'Micro Business Plan'" class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom ">
                            <div>
                                <div class="f5 b pb2">Prevent sales of items with no/zero inventory</div>
                                <div class="lh-copy pr1">
                                    Manage your inventory at zero stock level. Prevent your staff from recording sales of items with zero (0) stock level
                                </div>
                            </div>
                            <toggle-switch :checked="state.allowZeroStockLevel" @setCheckValue="setCheckZeroStockLevelValue" />
                        </div>
                    </div>
                    <div v-if="!orgData.isSubBusiness" class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom ">
                            <div>
                                <div class="f5 b pb2">Delete / Reset Account</div>
                                <div class="lh-copy pr1">
                                    Reset or delete your account on Simplebks
                                </div>
                            </div>
                            <router-link :to="{ name: 'ManageAccount' }" class="pv4-lx pv3x mini-spacing">
                                <button class="btn2 box-border">Manage</button>
                            </router-link>
                        </div>
                    </div>
                    <div class="pa3">
                        <div class="flex items-center justify-between pb3 box-border-bottom">
                            <div class="w-100">
                                <div class="f5 b pb2">Sales Receipt Footnote</div>
                                <div class="lh-copy pr1">
                                    Add a footnote that will appear in all your sales receipts
                                </div>
                                <div>
                                    <ckeditor 
                                        :editor="editor" 
                                        v-model="state.footnote" 
                                        :config="editorConfig" 
                                        tag-name="textarea"
                                        placeholder="Type your footnote here"
                                    ></ckeditor>
                                </div>
                                <div class="flex items-center justify-end pb3 box-border-bottom mt2">
                                    <button class="btn2 box-border" @click="updateAccountFootnoteDefault">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="pa3">
                        <div class=" flex items-center justify-between pb3 box-border-bottom ">
                            <div>
                                <div class="f5 b pb2">Time Zone</div>
                                <div class="lh-copy pr1">
                                    Simplebks uses your time zone to send summary and notification emails, for times in your
                                    activity feeds, and for reminders. Your time zone is currently set to:
                                    <span class="b">(WAT +1.00) West African Time</span>
                                </div>
                            </div>
                            <button class="btn2 box-border">Show</button>
                        </div>
                    </div>
                    <div class="pa3">
                        <div class=" flex flex-wrap items-center justify-between pb3 box-border-bottom ">
                            <div class="w-60-l  w-100">
                                <div class="f5 b pb2">Sign out all other sessions</div>
                                <div class="lh-copy pr1">
                                    Lost your phone? Left yourself logged in on a public computer? Need a way to sign out
                                    everywhere except your current browser? This is for you.
                                </div>
                            </div>
                            <button class="btn2 box-border  w-20-l w-30 mt0-l mt3">Sign out all</button>
                        </div>
                    </div> -->
                    <!-- <button id="button-update">Update</button> -->
                </div>
            </div>
            <!-- <div class="box-border w-60-l w-90 center mt4">
                <div class="box-border-bottom pa3">
                    <div class="f4 b pb2">Delete your account</div>
                    <div class="lh-copy pb3">
                        When you delete your account, you lose access to Front account services, and we permanently delete your
                        personal data. You can cancel the deletion for 14 days.
                    </div>
                    <div class="flex">
                        <img src="imgs/checkbox.svg" alt="" />
                        <div class="pl2" style="color: #132C8C;">Confirm that I want to delete my account.</div>
                    </div>
                </div>
                <div class="pa3">
                    <button style="background: #EB5757 ;">Delete Account</button>
                </div>
            </div> -->
        </template>
    </settings-wrapper>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import SettingsWrapper from '@/views/Settings/widgets/SettingsWrapper'
import OrgData from '@/components/OrgData'
import ToggleSwitch from '@/components/ToggleSwitch'
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'AccountSettings',
    components: {
        SettingsWrapper,
        OrgData,
        ToggleSwitch,
        ckeditor: CKEditor.component
    },

    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: [
                    'undo', 'redo', '|',
                    'bold', 'italic', 'numberedList', 'bulletedList', '|',
                    'link', '|',
                    'alignment', '|', 
                    'fontFamily', 'fontSize', 'fontColor'
                ],
                shouldNotGroupWhenFull: true
            }
        };
    },

    setup() {
        const store = useStore()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const organization = computed(() => store.state?.Settings?.organization)
        const accountStockLevel = computed(() => store.state?.Settings?.accountStockLevel)
        const accountTaxDefault = computed(() => store.state?.Settings?.accountTaxDefault)
        const plan = computed(() => store.state?.Settings?.paymentPlan)
        const currency = ref('')

        const initialState = () => ({
            stockLevel: 1,
            tax: 0,
            wipStatus: false,
            allowZeroStockLevel: false,
            footnote: ''
        })
        const state = reactive(initialState())

        const updateAccountStockLevel = () => {
            store.dispatch('Settings/updateAccountStockLevel', {
                stockLevel: state.stockLevel
            })
        }

        const updateAccountTaxDefault = () => {
            store.dispatch('Settings/updateAccountTaxDefault', {
                tax: state.tax
            })
        }

        const updateAccountFootnoteDefault = () => {
            store.dispatch('Settings/updateAccountFootnoteDefault', {
                footnote: state.footnote
            })
        }

        const onChangeCurrencySelect = e => {
            store.dispatch('Settings/pickCurrency', {
                currency: e
            })
        }

        const setCheckValue = (value) => {
            state.wipStatus = value
            store.dispatch('Settings/updateWipStatus', { wipStatus: state.wipStatus })
                .then((response) => {
                    if (response) {
                        state.wipStatus = value
                    } else {
                        state.wipStatus = !value
                    }
                })
        }

        const setCheckZeroStockLevelValue = (value) => {
            state.allowZeroStockLevel = value
            store.dispatch('Settings/updateAllowZeroStockLevelStatus', { allowZeroStockLevel: state.allowZeroStockLevel })
                .then((response) => {
                    if (response) {
                        state.allowZeroStockLevel = value
                    } else {
                        state.allowZeroStockLevel = !value
                    }
                })
        }

        onMounted(() => {
            store.dispatch('Settings/getPlan')
            store.dispatch('Settings/getAccountSettings').then(data => {
                if (data.status) {
                    state.stockLevel = data?.data?.stockLevel || 1
                    state.tax = data?.data?.tax || 0
                    state.wipStatus = data?.data?.wipStatus || false
                    state.allowZeroStockLevel = data?.data?.allowZeroStockLevel || false
                    state.footnote = data?.data?.footnote || ''
                    currency.value = data?.data?.currency
                }
            })
            // store.dispatch('Settings/getCurrency')
        })

        return {
            state,
            orgData,
            organization,
            accountStockLevel,
            accountTaxDefault,
            updateAccountStockLevel,
            updateAccountTaxDefault,
            updateAccountFootnoteDefault,
            currency,
            onChangeCurrencySelect,
            setCheckValue,
            setCheckZeroStockLevelValue,
            plan,
        }
    }
}
</script>

<style scoped>
#button-update {
    position: relative;
    padding: 15px 50px;
    margin: 20px;
    /* display: flex; */
    /* justify-content: flex-end; */
}
</style>
